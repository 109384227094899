<template>
    <div class="fill-height px-5 px-sm-10 py-5 py-sm-5" v-if="AppStore.started_app" id="timeline-page">
        <v-container class="fill-height" fluid>
            <v-row no-gutters style="flex-wrap: nowrap;" v-if="isLandscape">
                <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
                    <v-col cols="12" class="px-7" v-if="AppStore.data.configuration.display_thanks_messages">
                        <v-card class="text-subtitle-1 text-center pa-4" flat rounded="xl" id="timeline-thanks-message"
                                width="100%">
                            <v-col cols="12" class="text-h5 my-auto">
                                <span v-if="Helpers.isNegativeWithComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_with_comment"></span>
                                <span v-if="Helpers.isNegativeWithoutComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_without_comment"></span>
                                <span v-if="Helpers.isPositiveWithComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_with_comment"></span>
                                <span v-if="Helpers.isPositiveWithoutComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_without_comment"></span>
                            </v-col>
                            <v-col cols="12 pa-0">
                                <v-btn @click="$router.push({name: 'mb-1-home', query: AppStore.query_params});"
                                       v-if="!AppStore.data.general.post_single_rating"
                                       @keypress.enter="navigate" role="link" rounded
                                       outlined elevation="0"
                                       :large="AppStore.data.general.button_size === 'large'"
                                       :x-large="AppStore.data.general.button_size === 'x-large'"
                                       :x-small="AppStore.data.general.button_size === 'x-small'"
                                       :small="AppStore.data.general.button_size === 'small'"
                                       :color="AppStore.data.general.primary_color.hexa"
                                       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                                       class="ma-4">
                                    <v-icon left dark>
                                        mdi-arrow-left
                                    </v-icon>
                                    {{ AppStore.data.configuration[AppStore.locale].home_btn }}
                                </v-btn>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-col>
            </v-row>

            <v-row v-if="isPortrait" class="">
                <v-card elevation="0" class="text-subtitle-1 text-center pa-4 ma-0 ma-sm-5" flat rounded="xl"
                        id="timeline-thanks-message"
                        width="100%" v-if="AppStore.data.configuration.display_thanks_messages">
                    <v-col cols="12" class="text-h5 my-auto">

                        <span v-if="Helpers.isNegativeWithComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_with_comment"></span>
                        <span v-if="Helpers.isNegativeWithoutComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_without_comment"></span>
                        <span v-if="Helpers.isPositiveWithComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_with_comment"></span>
                        <span v-if="Helpers.isPositiveWithoutComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_without_comment"></span>
                    </v-col>
                    <v-col cols="12 pa-0">
                        <v-btn @click="$router.push({name: 'mb-1-home', query: AppStore.query_params});"
                               v-if="!AppStore.data.general.post_single_rating"
                               @keypress.enter="navigate" role="link" rounded
                               outlined elevation="0"
                               :large="AppStore.data.general.button_size === 'large'"
                               :x-large="AppStore.data.general.button_size === 'x-large'"
                               :x-small="AppStore.data.general.button_size === 'x-small'"
                               :small="AppStore.data.general.button_size === 'small'"
                               :color="AppStore.data.general.primary_color.hexa"
                               :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                               class="ma-4">
                            <v-icon left dark>
                                mdi-arrow-left
                            </v-icon>
                            {{ AppStore.data.configuration[AppStore.locale].home_btn }}
                        </v-btn>
                    </v-col>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import Helpers from "../../../services/Helpers";
import {EventBus} from "../../../services/Request";
import App from "../../../App.vue";

export default {
    name: 'timeline-view',

    components: {},

    props: {},

    data: () => ({
        AppStore,
        Helpers,
        EventBus,
        isLandscape: Helpers.isLandscape(),
        isPortrait: Helpers.isPortrait(),
    }),

    created() {
        window.addEventListener("orientationchange", () => {
            this.isLandscape = Helpers.isLandscape()
            this.isPortrait = Helpers.isPortrait()
        }, false);

        window.addEventListener("resize", () => {
            this.isLandscape = Helpers.isLandscape()
            this.isPortrait = Helpers.isPortrait()
        }, false);
    },

    mounted() {

    },

    computed: {
        App() {
            return App
        },
        _() {
            return _;
        },
    },

    watch: {},

    methods: {
    }
};
</script>
<style>
.stats {
    font-size: 3.5rem !important;
}
</style>
